const ReadTicks = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6_6)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.6212 2.34975C14.7874 2.24978 14.9859 2.21808 15.1749 2.26129C15.364 2.3045 15.529 2.41926 15.6352 2.5815L16.3777 3.714C16.4745 3.86195 16.516 4.03933 16.4947 4.21484C16.4734 4.39035 16.3908 4.55271 16.2615 4.67325L16.2592 4.67625L16.2487 4.686L16.206 4.72575L16.0372 4.887C15.1035 5.7927 14.1981 6.72715 13.3222 7.689C11.6745 9.501 9.71775 11.8725 8.40075 14.1735C8.03325 14.8155 7.1355 14.9535 6.603 14.3993L1.73925 9.34575C1.66954 9.2733 1.61512 9.18758 1.57921 9.09368C1.5433 8.99978 1.52663 8.89962 1.5302 8.79915C1.53376 8.69868 1.5575 8.59995 1.59998 8.50883C1.64246 8.41772 1.70283 8.33607 1.7775 8.26875L3.2475 6.94275C3.37668 6.82628 3.54232 6.75834 3.71608 6.75054C3.88984 6.74274 4.0609 6.79557 4.2 6.9L6.68175 8.76075C10.5585 4.938 12.7567 3.471 14.6212 2.34975Z"
          fill="#22C55E"
        />
      </g>
      <defs>
        <clipPath id="clip0_6_6">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReadTicks;
