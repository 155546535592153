const Twitter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M44.9201 12C43.3801 12.7 41.7201 13.16 40.0001 13.38C41.7601 12.32 43.1201 10.64 43.7601 8.62C42.1001 9.62 40.2601 10.32 38.3201 10.72C36.7401 9 34.5201 8 32.0001 8C27.3001 8 23.4601 11.84 23.4601 16.58C23.4601 17.26 23.5401 17.92 23.6801 18.54C16.5601 18.18 10.2201 14.76 6.00008 9.58C5.26008 10.84 4.84008 12.32 4.84008 13.88C4.84008 16.86 6.34008 19.5 8.66008 21C7.24008 21 5.92008 20.6 4.76008 20V20.06C4.76008 24.22 7.72008 27.7 11.6401 28.48C10.3815 28.8244 9.06027 28.8723 7.78008 28.62C8.32329 30.325 9.38715 31.8168 10.8221 32.8859C12.2571 33.9549 13.991 34.5474 15.7801 34.58C12.7473 36.9809 8.98807 38.2786 5.12008 38.26C4.44008 38.26 3.76008 38.22 3.08008 38.14C6.88008 40.58 11.4001 42 16.2401 42C32.0001 42 40.6601 28.92 40.6601 17.58C40.6601 17.2 40.6601 16.84 40.6401 16.46C42.3201 15.26 43.7601 13.74 44.9201 12Z"
        fill="#161616"
      />
    </svg>
  );
};

export default Twitter;
